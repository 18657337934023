/* Main */
header {
	display: flex;
	justify-content: flex-end;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	background-color: white;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);
}

/* Inner Divs */
.header-support a,
.header-user {
	border-left: 1px solid #e1e5eb;
	padding: 8px 20px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	position: relative;
}

/* Badge */
.badge {
	background-color: rgb(255, 68, 68);
	height: 19px;
	width: 19px;
	border-radius: 50%;
	text-align: center;
	line-height: 19px;
	font-size: 10px;
	font-family: sans-serif;
	color: #fff;
	position: absolute;
	right: 8px;
	top: 10px;
}

/* Header Support */
.header-support a {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	text-decoration: none;
}

.header-support a i:first-child {
	font-size: 1.7rem;
}

/* Header User */
.header-user {
	display: flex;
	align-items: center;
}

/* User Image */
.header-user img {
	width: 45px;
	height: 45px;
	margin-right: 8px;
	border-radius: 50%;
	object-fit: cover;
}

/* Name Span */
.header-name:hover,
.header-support a:hover i:first-child {
	color: rgba(0, 0, 0, 0.7);
}

/* Email Icon */
.header-support i:first-child {
	color: #c3c7cc;
	font-size: 1.3rem;
	transition: all 0.2s linear;
}

/* Sort Down Icon */
.header-user i {
	margin-left: 7px;
	margin-right: 10px;
}

/* 
======================
    Header Dropdown
======================
*/

/* Dropdown Container */
.header-dropdown {
	background-color: #fff;
	border-radius: 10px;
	margin-top: 14px;
	left: 0;
	position: absolute;
	width: 100%;
	display: none;
}

/* Links in Dropdown */
.header-dropdown a {
	text-decoration: none;
	color: inherit;
}

.header-dropdown div {
	padding: 8px 17px;
	cursor: pointer;
}

.header-dropdown div:nth-child(2) {
	color: red;
}

.header-dropdown div:hover {
	background-color: #fafafb;
}

.header-dropdown div:first-child {
	border-bottom: 1px solid #e1e5eb;
}

/* Media Query */
@media only screen and (max-width: 775px) {
	/* Main */
	header {
		justify-content: flex-start;
	}

	/* Inner Divs */
	.header-support a,
	.header-user {
		padding: 8px 20px;
	}

	/* User Img */
	.header-user img {
		height: 35px;
		width: 35px;
	}

	/* Dropdown */
	.header-dropdown {
		top: 30px;
	}
}
