/* Search */
.search-container {
	width: 320px;
	height: 40px;
	border-radius: 10px;
	margin-right: 20px;
	transition: all 0.2s linear;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	border: 2px solid var(--primary);
}

/* Select Options */
.search-container select:not(.market-search select) {
	height: 100%;
	width: 85px;
	outline: none;
	border: none;
	color: #5a6169;
	border-right: 1px solid #e1e5eb;
	text-align: center;
	padding-left: 5px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.search-container select:hover {
	color: var(--primary);
}

.market-search {
	display: flex;
	justify-content: space-between;
	height: 100%;
	width: 100%;
}

/* Market Search */
.market-search select {
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
	color: #5a6169;
	border-right: 1px solid #e1e5eb;
	text-align: center;
	font-size: 12px;
}

.search-container select:hover {
	color: var(--primary);
}

/* Search Button */
.search-container button {
	min-width: 40px;
	height: 40px;
	outline: none;
	border: none;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 11px;
	line-height: 17px;
	background-color: var(--primary);
	color: #fff;
	cursor: pointer;
}

/* Search Input */
.search-container input {
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	padding: 10px 15px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #495057;
}

@media only screen and (max-width: 775px) {
	.search-container {
		margin-right: 0;
		margin-top: 10px;
	}
}
