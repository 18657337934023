/* Main Profile */
.profile {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Profile Head */
.profile-heading span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #818ea3;
	letter-spacing: 2px;
}

.profile-heading h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
}

/* Profile Body */
.profile-body {
	margin-top: 25px;
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-column-gap: 2%;
}

/* Overview */
.profile-body-overview {
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	position: relative;
}

.profile-overview {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* Profile Image Container */
.profile-pic-container {
	width: 110px;
	height: 110px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
}

.profile-pic-container label {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.2);
	overflow: hidden;
	opacity: 0;
	transition: all 0.1s linear;
}

.profile-pic-container label:hover {
	opacity: 1;
	cursor: pointer;
}

.profile-pic-container label i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 1.3rem;
}

/* Profile Image */
.profile-overview img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* Name */
.profile-overview h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
	margin-top: 15px;
}

/* Role */
.profile-overview span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #868e96;
}

/* Progress section */
.profile-progress {
	width: 100%;
	height: 65px;
	padding: 12px 22px;
	position: absolute;
	bottom: 0;
	border-top: 1px solid #e1e5eb;
}

.profile-progress div {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #868e96;
	display: flex;
	justify-content: space-between;
}

/* Progress Bar */
.profile-progress progress {
	width: 100%;
	height: 7px;
	border-radius: 10px;
	overflow: hidden;
}

.profile-progress progress::-webkit-progress-value {
	background-color: var(--primary);
}

.profile-progress progress::-webkit-progress-bar {
	background-color: #e4e4e4;
}

/* Details */
.profile-body-details {
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.profile-body-details h4 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #3d5170;
	padding: 17px;
	border-bottom: 1px solid #e1e5eb;
}

/* Form */
.profile-body-details form {
	padding: 20px 15px;
}

/* all form inputs */
.profile-form input {
	width: 100%;
}

/* btn */
.profile-form .green-btn {
	margin: 5px;
}

/* Media */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	/* Profile Body */
	.profile-body {
		grid-template-columns: 1fr;
		grid-row-gap: 2%;
	}
	/* Profile Overview */
	.profile-body-overview {
		height: 350px;
	}
	.profile-pic-container {
		margin-top: -50px;
	}
	.profile-body-details {
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 775px) {
	/* Mian Heading */
	.profile-heading {
		text-align: center;
	}

	/* Profile Body */
	.profile-body {
		grid-template-columns: 1fr;
		grid-row-gap: 2%;
	}

	/* Profile Overview */
	.profile-body-overview {
		height: 350px;
	}
	.profile-pic-container {
		margin-top: -50px;
	}
	.profile-body-details {
		margin-bottom: 30px;
	}
}
