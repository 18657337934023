/* Main container */
.main {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Main heading */
.main h2:first-child {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
	padding-bottom: 25px;
}

/* Container */
.main-container {
	width: 100%;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	background-color: white;
	border: 1px solid #e1e5eb;
	border-radius: 13px;
	overflow: hidden;
}

/* Container Head */
.main-container-head {
	padding: 20px 17px 13px 17px;
	border-bottom: 1px solid #e1e5eb;
	display: flex;
	width: 100%;
	justify-content: space-between;
}

/* Container heading */
.main-container h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 35px;
	color: #3d5170;
	display: inline;
}

.main-container span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 300;
	font-size: 13px;
	line-height: 35px;
	color: #3d5170;
}

/* Table Container */
.table-container {
	width: 100%;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
	display: none;
}

/* table */
.main-table {
	width: 100%;
	min-width: 900px;
	text-align: center;
	border-collapse: collapse;
}

/* table head */
.main-table thead {
	width: 100%;
	background-color: #fbfbfb;
	border-bottom: 1px solid #e1e5eb;
}

.main-table thead tr {
	height: 50px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: 500;
	font-size: 13px;
	line-height: 23px;
	color: #5a6169;
}

.main-table thead tr th {
	padding: 10px;
}

/* Table body */
.main-table tbody tr {
	height: 80px;
	color: #5a6169;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 23px;
	border-bottom: 1px solid #e1e5eb;
}

.main-table tbody tr td {
	padding: 0 10px;
}

.main-table tbody tr img {
	width: 50px;
	height: 50px;
	object-fit: cover;
}

/* Green Button */
.green-btn {
	height: 35px;
	min-width: 75px;
	max-width: 120px;
	padding: 0 10px;
	outline: none;
	border: none;
	border-radius: 5px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 11px;
	line-height: 17px;
	background-color: var(--primary);
	color: #fff;
	cursor: pointer;
	transition: all 0.2s linear;
}

.green-btn:hover:enabled {
	background-color: #ff008a;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
		0 4px 10px rgba(255, 0, 191, 0.25);
}

/* Delete Button */
.del-btn {
	height: 35px;
	min-width: 75px;
	max-width: 110px;
	outline: none;
	border: none;
	border-radius: 5px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 11px;
	line-height: 17px;
	background-color: #dc3545;
	color: #fff;
	cursor: pointer;
	transition: all 0.2s linear;
}

.del-btn:hover:enabled {
	background-color: #c4183c;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
		0 4px 10px rgba(255, 60, 0, 0.25);
}

/* Enabled Button */
.enabled-btn {
	height: 35px;
	min-width: 75px;
	max-width: 110px;
	outline: none;
	border: none;
	border-radius: 5px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 11px;
	line-height: 17px;
	background-color: #17c671;
	color: #fff;
	cursor: pointer;
	transition: all 0.2s linear;
}

.enabled-btn:hover:enabled {
	background-color: #15af64;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
		0 4px 10px rgba(23, 198, 113, 0.25);
}

/* Media Query */
@media only screen and (max-width: 775px) {
	.main {
		padding: 85px 13px;
	}
	.main h2:first-child {
		text-align: center;
	}
	.main-container-head {
		flex-direction: column;
		align-items: center;
	}
	.main-table tbody tr {
		font-size: 13px;
	}
	.main-container h3 {
		line-height: 16px;
	}
	.action-heading {
		font-size: 13px !important;
		margin-bottom: 10px;
	}
}
