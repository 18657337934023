/* Main */
.signup {
  min-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Pseudo-element for the blurred background */
.signup::before {
  content: "";
  background: url("../assets/bg.jpg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(10px);
}

/* Container */
.signup-container {
  background-color: inherit !important;
  width: 30%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* Form */
.signup-container form {
  border: 1px solid var(--primary) !important;
  border-radius: 10px;
}

/* Button */
.signup-container .form-submit-btn {
  max-width: 100% !important;
  margin: 15px 5px 7px 5px;
}

/* Login Link */
.login-link-btn {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #818ea3;
}

/* Social Buttons */
.social_buttons {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.social_buttons a {
  min-width: 90px;
  width: 100%;
  height: 35px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  line-height: 35px;
  text-decoration: none;
  color: #455560;
  border: 1px solid #edf2f9;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: all 0.2s linear;
}

.social_buttons a:hover {
  background-color: #edf2f9;
}

/* Media Query */
@media only screen and (max-width: 775px) {
  .signup-container {
    transform: translateY(-5vh);
    width: 100%;
  }
  .signup-container .form-container section {
    width: 90%;
    margin: auto;
  }
}
