/* Main */
footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	z-index: 1;
	width: 100%;
	background-color: white;
	border-top: 1px solid #e1e5eb;
	height: 60px;
	padding-right: 25px;
	margin-top: -60px;
}

/* Text */
footer p {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
	color: #818ea3;
}

/* Links */
.link {
	color: var(--primary);
	text-decoration: none;
	cursor: pointer;
	transition: all 0.3s ease;
}

.link:hover {
	color: #0056b3;
}

/* Media Query */
@media only screen and (max-width: 775px) {
	/* Main */
	footer {
		justify-content: center;
		padding: 0;
	}

	/* Text */
	footer p {
		font-size: 15px;
		line-height: 23px;
		font-weight: 500;
	}
}
