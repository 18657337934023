/* Main Container */
.password {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Form container */
.password .form-container {
	flex-direction: column;
	min-width: 250px;
	max-width: 550px;
}

/* Inputs */
.password input {
	width: 100%;
}

/* Submit Button */
.password .form-submit-btn {
	margin-top: 15px;
}

@media only screen and (max-width: 775px) {
	.password input {
		margin: 5px 0 !important;
	}
}
