/* Main */
.error-page {
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Code */
.error-page span:first-child {
	font-family: sans-serif;
	font-size: 26px;
	font-weight: 100;
	color: #636b6f;
	padding: 5px 13px;
	border-right: 2px solid #636b6f;
}

/* Message */
.error-page span:last-child {
	font-family: sans-serif;
	font-size: 18px;
	font-weight: 100;
	color: #636b6f;
	padding: 0 13px;
}
