/* Main */
.excel label:not(.sort-label) {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #868e96;
	margin: 0 5px;
}

.excel .form-container {
	padding: 20px 0;
}

/* Inputs */
.excel input:not(#excel-user_no):not(#managerSort):not(#userSort) {
	width: 100%;
	margin: 5px 0 !important;
}

/* Date picker input */
.react-datepicker-wrapper {
	width: 100% !important;
}

/* Sort radio */
.excel .sort-radio {
	margin: 25px 0;
	width: 100%;
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	transition: border-color 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.excel .sort-radio div {
	display: flex;
	align-items: center;
}
.excel .sort-radio div label {
	margin-right: 10%;
}

/* User No Input */
#excel-user_no {
	margin: 25px 0 !important;
}

.excel section {
	flex-basis: 50%;
	padding: 0 20px;
}
.excel .excel-second-cols {
	padding: 0 20px;
	border-left: 1px solid #e1e5eb !important;
}
/* Select Options */
.excel select {
	margin: 25px 0;
	width: 100%;
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 0 10px;
	transition: border-color 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
}

@media only screen and (max-width: 1000px) {
	.excel .excel-second-cols {
		border-top: 1px solid #e1e5eb;
		border-left: none !important;
		margin-top: 40px;
		padding: 20px 0 0 0;
		margin-left: 0;
	}
}

@media only screen and (max-width: 775px) {
	#excel-user_no {
		margin: 20px 0 10px 0 !important;
	}
	.excel .sort-radio {
		margin: 10px 0;
	}
	.excel select[name="excel_status"] {
		margin: 10px 0 20px 0;
	}
}
