/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* CSS files */
@import url("./Dashboard.css");
@import url("./ErrorPage.css");
@import url("./Excel.css");
@import url("./Footer.css");
@import url("./Form.css");
@import url("./Header.css");
@import url("./Login.css");
@import url("./Pagination.css");
@import url("./Password.css");
@import url("./Profile.css");
@import url("./Public.css");
@import url("./Search.css");
@import url("./Sidebar.css");
@import url("./Signup.css");
@import url("./Support.css");
@import url("./Table.css");
@import url("./View.css");

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  padding-left: 275px;
}

/* Color Scheme */
:root {
  --gradient: linear-gradient(
    9deg,
    rgb(103, 59, 179) 0%,
    rgb(161, 87, 250) 50%,
    rgb(192, 108, 233) 100%
  );
  --primary: rgb(103, 59, 179);
  --shadow: rgba(103, 59, 179, 0.5);
}

/* Disabled button */
button:disabled {
  opacity: 0.5;
  transition: all 0.3s;
  cursor: auto;
}

.construction {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  font-size: 26px;
  color: #3d5170;
}

/* Image Model */
.image-model {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  padding-bottom: 5vh;
}

.image-model i {
  color: #fff;
  font-size: 2rem;
  display: block;
  text-align: right;
  margin-top: 3vh;
  margin-right: 5vh;
  cursor: pointer;
  transition: color 0.2s linear;
}

.image-model i:hover {
  color: rgba(255, 255, 255, 0.7);
}

.image-model img {
  max-width: 80%;
  height: 95%;
  object-fit: contain;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1300px) {
  #root {
    padding-left: 200px;
  }
}
@media only screen and (max-width: 775px) {
  body {
    background-color: #f2f3f5;
  }
  #root {
    padding: 0;
  }
}
