/* Main Container */
.form {
	background-color: #f5f6f8;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Heading */
.form h2:first-child {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
	padding-bottom: 25px;
}

/* Form */
.form form {
	width: 100%;
	background-color: white;
	padding: 20px 12px 30px 12px;
	border: 1px solid #e1e5eb;
}

/* Form heading */
.form form h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #868e96;
	padding-bottom: 13px;
	margin: 0 5px;
}

/* form */
.form-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

/* Bulk action container */
.bulk-action-container h3 {
	width: 50%;
	text-align: center;
}

.bulk-action-container .react-datepicker-wrapper input {
	width: 50%;
}

.orders-action-container input,
.orders-action-container .form-options {
	width: 100%;
}

/* Input container */
.two-form-col section {
	flex-basis: 49%;
}

.form-container section {
	width: 99%;
	display: flex;
	flex-direction: column;
}

/* Form row */
.form-container .form-row {
	margin: 5px 0;
}

/* all labels */
.form-container label:not(.sort-label) {
	display: block;
	margin-left: 10px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 14px;
	line-height: 23px;
	color: #5a6169;
}

/* Inputs */
.form-container input:not(#male):not(#female):not(#managerSort):not(#userSort) {
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	margin: 5px;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
}

/* Placeholder */
.form-container input::placeholder {
	font-size: 12px;
	letter-spacing: 1px;
}

/* Textaera */
.form-container textarea {
	border: none;
	outline: none;
	border: 1px solid #e1e5eb;
	margin: 5px;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
	resize: vertical;
}

.form-container
	input:not(#male):not(#female):not(#managerSort):not(#userSort):hover,
.form-container textarea:hover {
	border-color: #b3bdcc;
}

.form-container
	input:not(#male):not(#female):not(#managerSort):not(#userSort):focus,
.form-container textarea:focus {
	border-color: var(--primary);
	box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
		0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

/* Inputs File containers */
.input-file-container {
	width: 100%;
	padding: 5px;
	margin: 5px 0;
}

.input-file-container div {
	position: relative;
	overflow: hidden;
	border-radius: 5px;
}

.input-file-container input {
	width: 100%;
	padding: 6px !important;
	margin: 0 !important;
}

/* File Upload Progress */
.input-file-container progress {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	border-radius: 10px;
}

.input-file-container progress::-webkit-progress-value {
	background-color: var(--primary);
	transition: width 1s ease-in-out;
	-moz-transition: width 1s ease-in-out;
	-webkit-transition: width 1s ease-in-out;
	-o-transition: width 1s ease-in-out;
}

.input-file-container progress::-webkit-progress-bar {
	background-color: inherit;
}

/* Collapsed Inputs */
.form-container .form-input-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1%;
}

/* three inputs colspan */
.form-container .three-inputs-colspan {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1%;
}

/* two-colspan */
.form-container .two-inputs-colspan {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-column-gap: 1%;
}

/* Gender Radio */
.form-radio {
	margin: 5px;
	height: 35px;
	display: flex;
	align-items: center;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 15px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #495057;
}

.form-radio label {
	margin: 0 5px 0 10px;
}

/* Role Selector */

.form-options-container {
	display: flex;
	justify-content: space-between;
}

.form-options-container div {
	flex-basis: 49%;
}

.form-options {
	margin: 5px;
	height: 35px;
	display: flex;
	align-items: center;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 15px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #495057;
}

.form-options select {
	outline: none;
	margin: 0 5px 0 10px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #495057;
	border-color: #d1d7df;
}

.form-options select:not(#category) {
	width: 100%;
}

/* Submit Button */
.form-submit-btn {
	min-width: 90px;
	max-width: 130px;
	height: 35px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 12px;
	line-height: 14px;
	color: var(--primary);
	border: 1px solid var(--primary);
	outline: none;
	background-color: #fff;
	border-radius: 5px;
	cursor: pointer;
	margin: 5px;
	transition: all 0.2s linear;
}

.form-submit-btn:hover:enabled {
	background-color: var(--primary);
	color: white;
	box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.2);
}

/* Form two button container */
.form-buttons-container {
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-buttons-container button:first-child {
	margin: 5px;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	/* form container */
	.form-container {
		display: block;
	}
	/* Bulk action container */
	.bulk-action-container h3 {
		width: 100%;
		text-align: center;
	}

	.bulk-action-container .react-datepicker-wrapper input {
		width: 100%;
	}
}

@media only screen and (max-width: 775px) {
	/* form heading */
	.form h2:first-child {
		text-align: center;
	}

	/* form container */
	.form-container {
		display: block;
	}

	/* Bulk action container */
	.bulk-action-container h3 {
		width: 100%;
		text-align: center;
	}

	.bulk-action-container .react-datepicker-wrapper input {
		width: 100%;
	}

	/* Collapsed Inputs */
	.form-container .form-input-container {
		display: grid;
		grid-template-columns: 1fr;
	}

	/* three inputs colspan */
	.form-container .three-inputs-colspan {
		grid-template-columns: 1fr;
	}

	/* two-colspan */
	.form-container .two-inputs-colspan {
		grid-template-columns: 1fr;
	}

	/* Role Selector */
	.form-options-container {
		flex-direction: column;
	}

	/* radio */
	.form-radio {
		padding: 7px;
	}

	/* options */
	.form-options {
		padding: 7px;
	}
}
