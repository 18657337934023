/* Main */
.support-read {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 120px 25px 85px 25px;
}

/* Read page container */
.support-read-container {
	width: 85%;
	margin: auto;
}

/* Read page head */
.support-head {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

/* Heading */
.support-head h2 {
	font-size: 49px;
	font-weight: 400;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #3d5170;
}

/* Lists of messages */
.support-body li {
	list-style-type: none;
	padding: 20px 0 30px 0;
	border-bottom: 1px solid #e1e5eb;
}

/* Message info */
.support-body li p:first-child {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #cccccc;
	margin-bottom: 30px;
}

/* author */
.support-body li p:first-child span {
	color: var(--primary);
}

/* Message */
.support-body li p:last-child {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #5a6169;
}

/* Text Area */
.support-body textarea {
	width: 100%;
	border: none;
	outline: none;
	border: 1px solid #e1e5eb;
	margin-top: 15px;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
	resize: vertical;
}

.support-body textarea:hover {
	border-color: #b3bdcc;
}

.support-body textarea:focus {
	border-color: var(--primary);
	box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
		0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

/* Reply Button */
.reply-btn-container {
	margin-top: 20px;
	width: 100%;
	text-align: right;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	.support-read-container {
		width: 95%;
	}
	.support-head {
		flex-direction: column;
	}
	.support-head h2 {
		font-size: 28px;
	}
	.support-head-btn {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}
}

@media only screen and (max-width: 775px) {
	.issue-btn {
		margin: 8px 0 0 0 !important;
	}
	.support-read-container {
		width: 95%;
	}
	.support-head {
		flex-direction: column;
	}
	.support-head h2 {
		font-size: 22px;
	}
	.support-head-btn {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}
}
