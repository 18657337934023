@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
/* Main */
.dashboard {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Dashboard Head */
.dashboard-heading span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #818ea3;
	letter-spacing: 2px;
}

.dashboard-heading h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
}

/* Dashboard Body */
.dashboard-body {
	margin-top: 25px;
	display: grid;
	grid-row-gap: 30px;
}

/* rows */
.dashboard-first-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 2%;
}

.dashboard-second-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 2%;
}

.dashboard-third-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 2%;
}

.dashboard-fourth-row {
	display: grid;
	grid-template-columns: 66% 32%;
	grid-column-gap: 2%;
}

/* Dashboard Item */
.dashboard-item {
	padding: 25px;
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* Chart */
.chart {
	height: 440px;
	padding: 0;
}

/* Chart Heading */
.dashboard-item h2 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #3d5170;
	border-bottom: 1px solid #e7e7e7;
	width: 100%;
	padding: 15px;
}

.dashboard-item canvas {
	padding: 10px;
}

/* Item title */
.dashboard-item span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #818ea3;
	letter-spacing: 1px;
}

/* Item State */
.dashboard-item h6 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 33px;
	font-weight: 500;
	line-height: 50px;
	color: #3d5170;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	/* Chart */
	.chart {
		height: 300px;
	}
}

@media only screen and (max-width: 775px) {
	/* Main */
	.dashboard {
		text-align: center;
	}

	/* Dashboard Body */
	.dashboard-body {
		margin-top: 25px;
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	/* Rows */
	.dashboard-first-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	.dashboard-second-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	.dashboard-third-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	.dashboard-fourth-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	/* Chart */
	.chart {
		height: 250px;
	}
}

/* Main */
.error-page {
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Code */
.error-page span:first-child {
	font-family: sans-serif;
	font-size: 26px;
	font-weight: 100;
	color: #636b6f;
	padding: 5px 13px;
	border-right: 2px solid #636b6f;
}

/* Message */
.error-page span:last-child {
	font-family: sans-serif;
	font-size: 18px;
	font-weight: 100;
	color: #636b6f;
	padding: 0 13px;
}

/* Main */
.excel label:not(.sort-label) {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #868e96;
	margin: 0 5px;
}

.excel .form-container {
	padding: 20px 0;
}

/* Inputs */
.excel input:not(#excel-user_no):not(#managerSort):not(#userSort) {
	width: 100%;
	margin: 5px 0 !important;
}

/* Date picker input */
.react-datepicker-wrapper {
	width: 100% !important;
}

/* Sort radio */
.excel .sort-radio {
	margin: 25px 0;
	width: 100%;
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	transition: border-color 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.excel .sort-radio div {
	display: flex;
	align-items: center;
}
.excel .sort-radio div label {
	margin-right: 10%;
}

/* User No Input */
#excel-user_no {
	margin: 25px 0 !important;
}

.excel section {
	flex-basis: 50%;
	padding: 0 20px;
}
.excel .excel-second-cols {
	padding: 0 20px;
	border-left: 1px solid #e1e5eb !important;
}
/* Select Options */
.excel select {
	margin: 25px 0;
	width: 100%;
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 0 10px;
	transition: border-color 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
}

@media only screen and (max-width: 1000px) {
	.excel .excel-second-cols {
		border-top: 1px solid #e1e5eb;
		border-left: none !important;
		margin-top: 40px;
		padding: 20px 0 0 0;
		margin-left: 0;
	}
}

@media only screen and (max-width: 775px) {
	#excel-user_no {
		margin: 20px 0 10px 0 !important;
	}
	.excel .sort-radio {
		margin: 10px 0;
	}
	.excel select[name="excel_status"] {
		margin: 10px 0 20px 0;
	}
}

/* Main */
footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	z-index: 1;
	width: 100%;
	background-color: white;
	border-top: 1px solid #e1e5eb;
	height: 60px;
	padding-right: 25px;
	margin-top: -60px;
}

/* Text */
footer p {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
	color: #818ea3;
}

/* Links */
.link {
	color: var(--primary);
	text-decoration: none;
	cursor: pointer;
	transition: all 0.3s ease;
}

.link:hover {
	color: #0056b3;
}

/* Media Query */
@media only screen and (max-width: 775px) {
	/* Main */
	footer {
		justify-content: center;
		padding: 0;
	}

	/* Text */
	footer p {
		font-size: 15px;
		line-height: 23px;
		font-weight: 500;
	}
}

/* Main Container */
.form {
	background-color: #f5f6f8;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Heading */
.form h2:first-child {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
	padding-bottom: 25px;
}

/* Form */
.form form {
	width: 100%;
	background-color: white;
	padding: 20px 12px 30px 12px;
	border: 1px solid #e1e5eb;
}

/* Form heading */
.form form h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #868e96;
	padding-bottom: 13px;
	margin: 0 5px;
}

/* form */
.form-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

/* Bulk action container */
.bulk-action-container h3 {
	width: 50%;
	text-align: center;
}

.bulk-action-container .react-datepicker-wrapper input {
	width: 50%;
}

.orders-action-container input,
.orders-action-container .form-options {
	width: 100%;
}

/* Input container */
.two-form-col section {
	flex-basis: 49%;
}

.form-container section {
	width: 99%;
	display: flex;
	flex-direction: column;
}

/* Form row */
.form-container .form-row {
	margin: 5px 0;
}

/* all labels */
.form-container label:not(.sort-label) {
	display: block;
	margin-left: 10px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 14px;
	line-height: 23px;
	color: #5a6169;
}

/* Inputs */
.form-container input:not(#male):not(#female):not(#managerSort):not(#userSort) {
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	margin: 5px;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
}

/* Placeholder */
.form-container input::-webkit-input-placeholder {
	font-size: 12px;
	letter-spacing: 1px;
}
.form-container input::placeholder {
	font-size: 12px;
	letter-spacing: 1px;
}

/* Textaera */
.form-container textarea {
	border: none;
	outline: none;
	border: 1px solid #e1e5eb;
	margin: 5px;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
	resize: vertical;
}

.form-container
	input:not(#male):not(#female):not(#managerSort):not(#userSort):hover,
.form-container textarea:hover {
	border-color: #b3bdcc;
}

.form-container
	input:not(#male):not(#female):not(#managerSort):not(#userSort):focus,
.form-container textarea:focus {
	border-color: var(--primary);
	box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
		0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

/* Inputs File containers */
.input-file-container {
	width: 100%;
	padding: 5px;
	margin: 5px 0;
}

.input-file-container div {
	position: relative;
	overflow: hidden;
	border-radius: 5px;
}

.input-file-container input {
	width: 100%;
	padding: 6px !important;
	margin: 0 !important;
}

/* File Upload Progress */
.input-file-container progress {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	border-radius: 10px;
}

.input-file-container progress::-webkit-progress-value {
	background-color: var(--primary);
	transition: width 1s ease-in-out;
	-moz-transition: width 1s ease-in-out;
	-webkit-transition: width 1s ease-in-out;
	-o-transition: width 1s ease-in-out;
}

.input-file-container progress::-webkit-progress-bar {
	background-color: inherit;
}

/* Collapsed Inputs */
.form-container .form-input-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1%;
}

/* three inputs colspan */
.form-container .three-inputs-colspan {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1%;
}

/* two-colspan */
.form-container .two-inputs-colspan {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-column-gap: 1%;
}

/* Gender Radio */
.form-radio {
	margin: 5px;
	height: 35px;
	display: flex;
	align-items: center;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 15px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #495057;
}

.form-radio label {
	margin: 0 5px 0 10px;
}

/* Role Selector */

.form-options-container {
	display: flex;
	justify-content: space-between;
}

.form-options-container div {
	flex-basis: 49%;
}

.form-options {
	margin: 5px;
	height: 35px;
	display: flex;
	align-items: center;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 15px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #495057;
}

.form-options select {
	outline: none;
	margin: 0 5px 0 10px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #495057;
	border-color: #d1d7df;
}

.form-options select:not(#category) {
	width: 100%;
}

/* Submit Button */
.form-submit-btn {
	min-width: 90px;
	max-width: 130px;
	height: 35px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 12px;
	line-height: 14px;
	color: var(--primary);
	border: 1px solid var(--primary);
	outline: none;
	background-color: #fff;
	border-radius: 5px;
	cursor: pointer;
	margin: 5px;
	transition: all 0.2s linear;
}

.form-submit-btn:hover:enabled {
	background-color: var(--primary);
	color: white;
	box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.2);
}

/* Form two button container */
.form-buttons-container {
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-buttons-container button:first-child {
	margin: 5px;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	/* form container */
	.form-container {
		display: block;
	}
	/* Bulk action container */
	.bulk-action-container h3 {
		width: 100%;
		text-align: center;
	}

	.bulk-action-container .react-datepicker-wrapper input {
		width: 100%;
	}
}

@media only screen and (max-width: 775px) {
	/* form heading */
	.form h2:first-child {
		text-align: center;
	}

	/* form container */
	.form-container {
		display: block;
	}

	/* Bulk action container */
	.bulk-action-container h3 {
		width: 100%;
		text-align: center;
	}

	.bulk-action-container .react-datepicker-wrapper input {
		width: 100%;
	}

	/* Collapsed Inputs */
	.form-container .form-input-container {
		display: grid;
		grid-template-columns: 1fr;
	}

	/* three inputs colspan */
	.form-container .three-inputs-colspan {
		grid-template-columns: 1fr;
	}

	/* two-colspan */
	.form-container .two-inputs-colspan {
		grid-template-columns: 1fr;
	}

	/* Role Selector */
	.form-options-container {
		flex-direction: column;
	}

	/* radio */
	.form-radio {
		padding: 7px;
	}

	/* options */
	.form-options {
		padding: 7px;
	}
}

/* Main */
header {
	display: flex;
	justify-content: flex-end;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	background-color: white;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);
}

/* Inner Divs */
.header-support a,
.header-user {
	border-left: 1px solid #e1e5eb;
	padding: 8px 20px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	position: relative;
}

/* Badge */
.badge {
	background-color: rgb(255, 68, 68);
	height: 19px;
	width: 19px;
	border-radius: 50%;
	text-align: center;
	line-height: 19px;
	font-size: 10px;
	font-family: sans-serif;
	color: #fff;
	position: absolute;
	right: 8px;
	top: 10px;
}

/* Header Support */
.header-support a {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	text-decoration: none;
}

.header-support a i:first-child {
	font-size: 1.7rem;
}

/* Header User */
.header-user {
	display: flex;
	align-items: center;
}

/* User Image */
.header-user img {
	width: 45px;
	height: 45px;
	margin-right: 8px;
	border-radius: 50%;
	object-fit: cover;
}

/* Name Span */
.header-name:hover,
.header-support a:hover i:first-child {
	color: rgba(0, 0, 0, 0.7);
}

/* Email Icon */
.header-support i:first-child {
	color: #c3c7cc;
	font-size: 1.3rem;
	transition: all 0.2s linear;
}

/* Sort Down Icon */
.header-user i {
	margin-left: 7px;
	margin-right: 10px;
}

/* 
======================
    Header Dropdown
======================
*/

/* Dropdown Container */
.header-dropdown {
	background-color: #fff;
	border-radius: 10px;
	margin-top: 14px;
	left: 0;
	position: absolute;
	width: 100%;
	display: none;
}

/* Links in Dropdown */
.header-dropdown a {
	text-decoration: none;
	color: inherit;
}

.header-dropdown div {
	padding: 8px 17px;
	cursor: pointer;
}

.header-dropdown div:nth-child(2) {
	color: red;
}

.header-dropdown div:hover {
	background-color: #fafafb;
}

.header-dropdown div:first-child {
	border-bottom: 1px solid #e1e5eb;
}

/* Media Query */
@media only screen and (max-width: 775px) {
	/* Main */
	header {
		justify-content: flex-start;
	}

	/* Inner Divs */
	.header-support a,
	.header-user {
		padding: 8px 20px;
	}

	/* User Img */
	.header-user img {
		height: 35px;
		width: 35px;
	}

	/* Dropdown */
	.header-dropdown {
		top: 30px;
	}
}

/* Main Container */
.login-container {
  min-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure no overflow */
}

/* Pseudo-element for the blurred background */
.login-container::before {
  content: "";
  background: url(/static/media/bg.9da4f5f1.jpg) no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}

.login {
  width: 30%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* Logo */
.login-logo {
  width: 130px;
}

.login-logo img {
  width: 100%;
}

/* Form */
.login form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

/* Input */
.login form input {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.6px;
  color: #555555;
  width: 400px;
  margin: 20px 0;
  outline: none;
  padding: 15px 3px;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  transition: all 0.2s linear;
  background-color: inherit;
}

.login form input:focus {
  border-bottom: 2px solid var(--primary);
}

.login form input::-webkit-input-placeholder {
  font-family: "Poppins", sans-serif;
  padding-left: 12px;
  color: #9c9fa9;
  font-size: 18px;
  font-weight: 400;
}

.login form input::placeholder {
  font-family: "Poppins", sans-serif;
  padding-left: 12px;
  color: #9c9fa9;
  font-size: 18px;
  font-weight: 400;
}

/* Button */
.login form button:not(.close) {
  padding: 12px 0;
  margin-top: 20px;
  width: 400px;
  outline: none;
  border: none;
  border-radius: 25px;
  color: white;
  background: var(--gradient);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0 10px 30px 0px var(--shadow);
  transition: all 0.3s linear;
  background-position: left;
  background-size: 150%;
}

.login form button:not(.close):hover {
  cursor: pointer;
  background-position: right;
}

.login form button:disabled {
  background-position: left !important;
  opacity: 0.5;
  transition: all 0.3s;
}

.signup-btn {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #818ea3;
  margin-top: 20px;
}

/* Media Query */
@media only screen and (max-width: 775px) {
  .login-container {
    min-height: 100vh;
  }
  .login {
    -webkit-transform: translateY(-10vh);
            transform: translateY(-10vh);
    width: 100%;
  }
  .login form input,
  .login form button:not(.close) {
    width: 80%;
  }
}

/* Pagination */
.pagination {
	margin: 15px auto;
	display: flex;
	justify-content: center;
	list-style: none;
	outline: none;
}
.pagination > .active > a {
	background-color: var(--primary);
	border-color: var(--primary);
	color: #fff;
}
.pagination > li > a {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	border: 1px solid var(--primary);
	padding: 5px 10px;
	outline: none;
	cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: var(--primary);
	border-color: var(--primary);
	outline: none;
}
.pagination > li > a,
.pagination > li > span {
	color: var(--primary);
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 300;
	transition: all 0.2s linear;
}
.pagination > li > a:hover,
.pagination > li > span:hover {
	background-color: rgb(236, 236, 236);
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
}

/* Main Container */
.password {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Form container */
.password .form-container {
	flex-direction: column;
	min-width: 250px;
	max-width: 550px;
}

/* Inputs */
.password input {
	width: 100%;
}

/* Submit Button */
.password .form-submit-btn {
	margin-top: 15px;
}

@media only screen and (max-width: 775px) {
	.password input {
		margin: 5px 0 !important;
	}
}

/* Main Profile */
.profile {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Profile Head */
.profile-heading span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #818ea3;
	letter-spacing: 2px;
}

.profile-heading h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
}

/* Profile Body */
.profile-body {
	margin-top: 25px;
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-column-gap: 2%;
}

/* Overview */
.profile-body-overview {
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	position: relative;
}

.profile-overview {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* Profile Image Container */
.profile-pic-container {
	width: 110px;
	height: 110px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
}

.profile-pic-container label {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.2);
	overflow: hidden;
	opacity: 0;
	transition: all 0.1s linear;
}

.profile-pic-container label:hover {
	opacity: 1;
	cursor: pointer;
}

.profile-pic-container label i {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	color: white;
	font-size: 1.3rem;
}

/* Profile Image */
.profile-overview img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* Name */
.profile-overview h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
	margin-top: 15px;
}

/* Role */
.profile-overview span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #868e96;
}

/* Progress section */
.profile-progress {
	width: 100%;
	height: 65px;
	padding: 12px 22px;
	position: absolute;
	bottom: 0;
	border-top: 1px solid #e1e5eb;
}

.profile-progress div {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
	color: #868e96;
	display: flex;
	justify-content: space-between;
}

/* Progress Bar */
.profile-progress progress {
	width: 100%;
	height: 7px;
	border-radius: 10px;
	overflow: hidden;
}

.profile-progress progress::-webkit-progress-value {
	background-color: var(--primary);
}

.profile-progress progress::-webkit-progress-bar {
	background-color: #e4e4e4;
}

/* Details */
.profile-body-details {
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.profile-body-details h4 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #3d5170;
	padding: 17px;
	border-bottom: 1px solid #e1e5eb;
}

/* Form */
.profile-body-details form {
	padding: 20px 15px;
}

/* all form inputs */
.profile-form input {
	width: 100%;
}

/* btn */
.profile-form .green-btn {
	margin: 5px;
}

/* Media */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	/* Profile Body */
	.profile-body {
		grid-template-columns: 1fr;
		grid-row-gap: 2%;
	}
	/* Profile Overview */
	.profile-body-overview {
		height: 350px;
	}
	.profile-pic-container {
		margin-top: -50px;
	}
	.profile-body-details {
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 775px) {
	/* Mian Heading */
	.profile-heading {
		text-align: center;
	}

	/* Profile Body */
	.profile-body {
		grid-template-columns: 1fr;
		grid-row-gap: 2%;
	}

	/* Profile Overview */
	.profile-body-overview {
		height: 350px;
	}
	.profile-pic-container {
		margin-top: -50px;
	}
	.profile-body-details {
		margin-bottom: 30px;
	}
}

/* Main */
.public {
	position: absolute;
	width: 100%;
	min-height: 100vh;
	left: 0;
}

/* Heading */
.public h2 {
	text-align: center;
	padding-bottom: 10px !important;
}

/* Slogan */
.public p {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #3d5170;
	padding-bottom: 25px;
	text-align: center;
	letter-spacing: 3px;
}

/* Table Row */
.public tbody tr {
	height: 160px !important;
}

/* Product Image */
.public tbody tr img {
	width: 145px !important;
	height: 145px !important;
	object-fit: cover;
}

/* Search */
.search-container {
	width: 320px;
	height: 40px;
	border-radius: 10px;
	margin-right: 20px;
	transition: all 0.2s linear;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	border: 2px solid var(--primary);
}

/* Select Options */
.search-container select:not(.market-search select) {
	height: 100%;
	width: 85px;
	outline: none;
	border: none;
	color: #5a6169;
	border-right: 1px solid #e1e5eb;
	text-align: center;
	padding-left: 5px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.search-container select:hover {
	color: var(--primary);
}

.market-search {
	display: flex;
	justify-content: space-between;
	height: 100%;
	width: 100%;
}

/* Market Search */
.market-search select {
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
	color: #5a6169;
	border-right: 1px solid #e1e5eb;
	text-align: center;
	font-size: 12px;
}

.search-container select:hover {
	color: var(--primary);
}

/* Search Button */
.search-container button {
	min-width: 40px;
	height: 40px;
	outline: none;
	border: none;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 11px;
	line-height: 17px;
	background-color: var(--primary);
	color: #fff;
	cursor: pointer;
}

/* Search Input */
.search-container input {
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	padding: 10px 15px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #495057;
}

@media only screen and (max-width: 775px) {
	.search-container {
		margin-right: 0;
		margin-top: 10px;
	}
}

/* Main Sidebar */
aside {
  width: 275px;
  min-width: 190px;
  height: 100%;
  box-shadow:
    rgba(90, 97, 105, 0.1) 0px 0.125rem 9.375rem,
    rgba(90, 97, 105, 0.12) 0px 0.25rem 0.5rem,
    rgba(90, 97, 105, 0.1) 0px 0.9375rem 1.375rem,
    rgba(165, 182, 201, 0.1) 0px 0.4375rem 2.1875rem;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background-color: #fff;
}
aside::-webkit-scrollbar {
  display: none;
}

/* Head */
.aside-head {
  width: 100%;
  height: 60px;
  text-align: center;
  border-bottom: 1px solid #e1e5eb;
  position: relative;
}

.aside-head a {
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

.aside-head a:hover {
  color: rgba(0, 0, 0, 1);
}

.aside-head a img {
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-right: 5px;
}

.toggle-sidebar {
  display: none;
}

/* Aside Items */
.aside-item {
  width: 100%;
  text-align: left;
}

.aside-item a {
  color: inherit;
  text-decoration: none;
}

/* Button */
.aside-item button {
  border: none;
  outline: none;
  background-color: inherit;
  border-bottom: 1px solid #e1e5eb;
  cursor: pointer;
  height: 50px;
  width: 100%;
  padding-left: 25px;
  color: #3d5170;
  display: flex;
  align-items: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-left: 4px solid transparent;
  transition: all 0.2s linear;
}

.aside-item i {
  color: #cacedb;
}

.aside-item span {
  margin-left: 9px;
}

/* Button Hover Effects */
.aside-item button:hover,
.aside-item:hover button i,
.aside-item:hover .dropdown-container a span,
.aside-item:hover .dropdown-container a i {
  color: var(--primary);
  transition: all 0.2s linear;
}

.aside-item:hover button {
  border-left: 4px solid var(--primary);
}

/* Dropdown */
.dropdown-container {
  display: flex;
  flex-direction: column;
  background-color: #f5f6f8;
  padding-left: 23px;
  display: none;
}

.dropdown-container a {
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 13px 0 13px 25px;
  border-bottom: 1px solid #e1e5eb;
  color: #3d5170;
  display: flex;
  align-items: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-left: 3px solid transparent;
  transition: all 0.2s linear;
}

/* Dropdown items hover effects */
.dropdown-container a:hover {
  background-color: white;
}

.dropdown-container a:hover {
  border-left: 3px solid var(--primary);
}

/* Active link styles */

a.nav-active button {
  border-left: 4px solid var(--primary);
}

a.nav-active button i {
  color: var(--primary);
  transition: all 0.2s linear;
}

a.dropdown-active {
  border-left: 3px solid var(--primary);
}

a.dropdown-active i,
a.dropdown-active span {
  color: var(--primary);
  transition: all 0.2s linear;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1300px) {
  aside {
    width: 200px;
  }
  .dropdown-container {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 775px) {
  /* Mian Sidebar */
  aside {
    width: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all 0.2s ease-in-out;
  }

  #sidebar {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  /* Toggle Button */
  .toggle-sidebar {
    display: inline;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-left: 1px solid #e1e5eb;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    width: 60px;
    color: #5a6169;
    cursor: pointer;
  }
}

/* Main */
.signup {
  min-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Pseudo-element for the blurred background */
.signup::before {
  content: "";
  background: url(/static/media/bg.9da4f5f1.jpg) no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}

/* Container */
.signup-container {
  background-color: inherit !important;
  width: 30%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* Form */
.signup-container form {
  border: 1px solid var(--primary) !important;
  border-radius: 10px;
}

/* Button */
.signup-container .form-submit-btn {
  max-width: 100% !important;
  margin: 15px 5px 7px 5px;
}

/* Login Link */
.login-link-btn {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #818ea3;
}

/* Social Buttons */
.social_buttons {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.social_buttons a {
  min-width: 90px;
  width: 100%;
  height: 35px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  line-height: 35px;
  text-decoration: none;
  color: #455560;
  border: 1px solid #edf2f9;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: all 0.2s linear;
}

.social_buttons a:hover {
  background-color: #edf2f9;
}

/* Media Query */
@media only screen and (max-width: 775px) {
  .signup-container {
    -webkit-transform: translateY(-5vh);
            transform: translateY(-5vh);
    width: 100%;
  }
  .signup-container .form-container section {
    width: 90%;
    margin: auto;
  }
}

/* Main */
.support-read {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 120px 25px 85px 25px;
}

/* Read page container */
.support-read-container {
	width: 85%;
	margin: auto;
}

/* Read page head */
.support-head {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

/* Heading */
.support-head h2 {
	font-size: 49px;
	font-weight: 400;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #3d5170;
}

/* Lists of messages */
.support-body li {
	list-style-type: none;
	padding: 20px 0 30px 0;
	border-bottom: 1px solid #e1e5eb;
}

/* Message info */
.support-body li p:first-child {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #cccccc;
	margin-bottom: 30px;
}

/* author */
.support-body li p:first-child span {
	color: var(--primary);
}

/* Message */
.support-body li p:last-child {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #5a6169;
}

/* Text Area */
.support-body textarea {
	width: 100%;
	border: none;
	outline: none;
	border: 1px solid #e1e5eb;
	margin-top: 15px;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
	resize: vertical;
}

.support-body textarea:hover {
	border-color: #b3bdcc;
}

.support-body textarea:focus {
	border-color: var(--primary);
	box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
		0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

/* Reply Button */
.reply-btn-container {
	margin-top: 20px;
	width: 100%;
	text-align: right;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	.support-read-container {
		width: 95%;
	}
	.support-head {
		flex-direction: column;
	}
	.support-head h2 {
		font-size: 28px;
	}
	.support-head-btn {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}
}

@media only screen and (max-width: 775px) {
	.issue-btn {
		margin: 8px 0 0 0 !important;
	}
	.support-read-container {
		width: 95%;
	}
	.support-head {
		flex-direction: column;
	}
	.support-head h2 {
		font-size: 22px;
	}
	.support-head-btn {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}
}

/* Main container */
.main {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Main heading */
.main h2:first-child {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
	padding-bottom: 25px;
}

/* Container */
.main-container {
	width: 100%;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	background-color: white;
	border: 1px solid #e1e5eb;
	border-radius: 13px;
	overflow: hidden;
}

/* Container Head */
.main-container-head {
	padding: 20px 17px 13px 17px;
	border-bottom: 1px solid #e1e5eb;
	display: flex;
	width: 100%;
	justify-content: space-between;
}

/* Container heading */
.main-container h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 35px;
	color: #3d5170;
	display: inline;
}

.main-container span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 300;
	font-size: 13px;
	line-height: 35px;
	color: #3d5170;
}

/* Table Container */
.table-container {
	width: 100%;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
	display: none;
}

/* table */
.main-table {
	width: 100%;
	min-width: 900px;
	text-align: center;
	border-collapse: collapse;
}

/* table head */
.main-table thead {
	width: 100%;
	background-color: #fbfbfb;
	border-bottom: 1px solid #e1e5eb;
}

.main-table thead tr {
	height: 50px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: 500;
	font-size: 13px;
	line-height: 23px;
	color: #5a6169;
}

.main-table thead tr th {
	padding: 10px;
}

/* Table body */
.main-table tbody tr {
	height: 80px;
	color: #5a6169;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 23px;
	border-bottom: 1px solid #e1e5eb;
}

.main-table tbody tr td {
	padding: 0 10px;
}

.main-table tbody tr img {
	width: 50px;
	height: 50px;
	object-fit: cover;
}

/* Green Button */
.green-btn {
	height: 35px;
	min-width: 75px;
	max-width: 120px;
	padding: 0 10px;
	outline: none;
	border: none;
	border-radius: 5px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 11px;
	line-height: 17px;
	background-color: var(--primary);
	color: #fff;
	cursor: pointer;
	transition: all 0.2s linear;
}

.green-btn:hover:enabled {
	background-color: #ff008a;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
		0 4px 10px rgba(255, 0, 191, 0.25);
}

/* Delete Button */
.del-btn {
	height: 35px;
	min-width: 75px;
	max-width: 110px;
	outline: none;
	border: none;
	border-radius: 5px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 11px;
	line-height: 17px;
	background-color: #dc3545;
	color: #fff;
	cursor: pointer;
	transition: all 0.2s linear;
}

.del-btn:hover:enabled {
	background-color: #c4183c;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
		0 4px 10px rgba(255, 60, 0, 0.25);
}

/* Enabled Button */
.enabled-btn {
	height: 35px;
	min-width: 75px;
	max-width: 110px;
	outline: none;
	border: none;
	border-radius: 5px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 11px;
	line-height: 17px;
	background-color: #17c671;
	color: #fff;
	cursor: pointer;
	transition: all 0.2s linear;
}

.enabled-btn:hover:enabled {
	background-color: #15af64;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
		0 4px 10px rgba(23, 198, 113, 0.25);
}

/* Media Query */
@media only screen and (max-width: 775px) {
	.main {
		padding: 85px 13px;
	}
	.main h2:first-child {
		text-align: center;
	}
	.main-container-head {
		flex-direction: column;
		align-items: center;
	}
	.main-table tbody tr {
		font-size: 13px;
	}
	.main-container h3 {
		line-height: 16px;
	}
	.action-heading {
		font-size: 13px !important;
		margin-bottom: 10px;
	}
}

/* Main View */

.view {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* View Head */
.view-heading span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #818ea3;
	letter-spacing: 2px;
}

.view-heading h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
}

/* View Body */
.view-body {
	margin-top: 25px;
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-column-gap: 2%;
}

/* View Pics Section */
.view-pics {
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.invoice-pic {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.view-pic,
.view-amz-pic {
	padding: 35px 0;
	border-bottom: 1px solid #e1e5eb;
}

/* Headings */
.view-pics h3 {
	font-weight: 500;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 20px;
	line-height: 24px;
	color: #3d5170;
	margin-bottom: 20px;
}

/* Images */
.view-pics img {
	width: 100%;
	max-width: 230px;
	object-fit: cover;
	display: block;
	margin: 20px auto;
	transition: all 0.3s;
	cursor: pointer;
}

.view-pics img:hover {
	opacity: 0.7;
}

/* File Uploaders */
.view-pics label {
	padding: 12px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 12px;
	line-height: 14px;
	color: var(--primary);
	border: 1px solid var(--primary);
	outline: none;
	background-color: #fff;
	border-radius: 50px;
	cursor: pointer;
	margin: 5px;
	transition: all 0.2s linear;
	margin-top: 20px;
}

.view-pics label:hover {
	background-color: var(--primary);
	color: white;
	box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.2);
}

/* View Details Section */
.view-details {
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* Head */
.view-details-head {
	border-bottom: 1px solid #e1e5eb;
	display: flex;
	align-items: center;
	padding: 17px;
	position: relative;
}

/* Copy Button */
.view-details-head #copy_icon {
	color: #3d5170;
	font-size: 1.4rem;
	position: absolute;
	right: 30px;
	cursor: pointer;
	transition: all 0.2s linear;
}

.view-details-head #copy_icon:hover {
	color: #e1e5eb;
}

.view-details-head #copy_icon:active {
	color: var(--primary);
	font-size: 3rem;
}

/* Heading */
.view-details h4 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #3d5170;
	margin-right: 25px;
}

/* Details */
.view-details ul {
	display: grid;
	grid-template-rows: auto;
	grid-row-gap: 35px;
	padding: 35px;
}

/* Rows */
.view-details ul li {
	list-style-type: none;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2%;
}

/* Heading */
.view-details ul li div h5 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: #3d5170;
}

/* Data */
.view-details ul li div span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 15px;
	line-height: 40px;
	font-weight: 300;
	color: #5a6169;
	padding-left: 25px;
}

/* Form */

/* Heading */
.view-update-form h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 500;
	color: #868e96;
	margin-bottom: -35px;
}

/* Inputs */
.view-update-form input {
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
}

.view-update-form select:not(.form-options select) {
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 0 10px;
	transition: border-color 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
}

/* Placeholder */
.view-update-form input::-webkit-input-placeholder {
	font-size: 12px;
	letter-spacing: 1px;
}
.view-update-form input::placeholder {
	font-size: 12px;
	letter-spacing: 1px;
}

/* Textaera */
.view-update-form textarea {
	border: none;
	outline: none;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
	resize: vertical;
}

.view-update-form input:hover,
.view-update-form textarea:hover {
	border-color: #b3bdcc;
}

.view-update-form input:focus,
.view-update-form textarea:focus,
.view-update-form select:focus {
	border-color: var(--primary);
	box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
		0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	/* Body */
	.view-body {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 2%;
	}
	/* View details sections */
	.view-details {
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 775px) {
	/* Main Heading */
	.view-heading {
		text-align: center;
	}

	/* Body */
	.view-body {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 2%;
	}

	/* View details sections */
	.view-details {
		margin-bottom: 40px;
	}

	/* Details */
	.view-details ul {
		display: grid;
		grid-template-rows: auto;
		grid-row-gap: 20px;
		padding: 20px;
	}

	/* List items in details section */
	.view-details ul li {
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
	}
}

/* Fonts */

/* CSS files */

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  padding-left: 275px;
}

/* Color Scheme */
:root {
  --gradient: linear-gradient(
    9deg,
    rgb(103, 59, 179) 0%,
    rgb(161, 87, 250) 50%,
    rgb(192, 108, 233) 100%
  );
  --primary: rgb(103, 59, 179);
  --shadow: rgba(103, 59, 179, 0.5);
}

/* Disabled button */
button:disabled {
  opacity: 0.5;
  transition: all 0.3s;
  cursor: auto;
}

.construction {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  font-size: 26px;
  color: #3d5170;
}

/* Image Model */
.image-model {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  padding-bottom: 5vh;
}

.image-model i {
  color: #fff;
  font-size: 2rem;
  display: block;
  text-align: right;
  margin-top: 3vh;
  margin-right: 5vh;
  cursor: pointer;
  transition: color 0.2s linear;
}

.image-model i:hover {
  color: rgba(255, 255, 255, 0.7);
}

.image-model img {
  max-width: 80%;
  height: 95%;
  object-fit: contain;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1300px) {
  #root {
    padding-left: 200px;
  }
}
@media only screen and (max-width: 775px) {
  body {
    background-color: #f2f3f5;
  }
  #root {
    padding: 0;
  }
}

