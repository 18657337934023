/* Main */
.public {
	position: absolute;
	width: 100%;
	min-height: 100vh;
	left: 0;
}

/* Heading */
.public h2 {
	text-align: center;
	padding-bottom: 10px !important;
}

/* Slogan */
.public p {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	color: #3d5170;
	padding-bottom: 25px;
	text-align: center;
	letter-spacing: 3px;
}

/* Table Row */
.public tbody tr {
	height: 160px !important;
}

/* Product Image */
.public tbody tr img {
	width: 145px !important;
	height: 145px !important;
	object-fit: cover;
}
