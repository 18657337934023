/* Main View */

.view {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* View Head */
.view-heading span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #818ea3;
	letter-spacing: 2px;
}

.view-heading h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
}

/* View Body */
.view-body {
	margin-top: 25px;
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-column-gap: 2%;
}

/* View Pics Section */
.view-pics {
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.invoice-pic {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.view-pic,
.view-amz-pic {
	padding: 35px 0;
	border-bottom: 1px solid #e1e5eb;
}

/* Headings */
.view-pics h3 {
	font-weight: 500;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 20px;
	line-height: 24px;
	color: #3d5170;
	margin-bottom: 20px;
}

/* Images */
.view-pics img {
	width: 100%;
	max-width: 230px;
	object-fit: cover;
	display: block;
	margin: 20px auto;
	transition: all 0.3s;
	cursor: pointer;
}

.view-pics img:hover {
	opacity: 0.7;
}

/* File Uploaders */
.view-pics label {
	padding: 12px;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 12px;
	line-height: 14px;
	color: var(--primary);
	border: 1px solid var(--primary);
	outline: none;
	background-color: #fff;
	border-radius: 50px;
	cursor: pointer;
	margin: 5px;
	transition: all 0.2s linear;
	margin-top: 20px;
}

.view-pics label:hover {
	background-color: var(--primary);
	color: white;
	box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.2);
}

/* View Details Section */
.view-details {
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* Head */
.view-details-head {
	border-bottom: 1px solid #e1e5eb;
	display: flex;
	align-items: center;
	padding: 17px;
	position: relative;
}

/* Copy Button */
.view-details-head #copy_icon {
	color: #3d5170;
	font-size: 1.4rem;
	position: absolute;
	right: 30px;
	cursor: pointer;
	transition: all 0.2s linear;
}

.view-details-head #copy_icon:hover {
	color: #e1e5eb;
}

.view-details-head #copy_icon:active {
	color: var(--primary);
	font-size: 3rem;
}

/* Heading */
.view-details h4 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #3d5170;
	margin-right: 25px;
}

/* Details */
.view-details ul {
	display: grid;
	grid-template-rows: auto;
	grid-row-gap: 35px;
	padding: 35px;
}

/* Rows */
.view-details ul li {
	list-style-type: none;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2%;
}

/* Heading */
.view-details ul li div h5 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: #3d5170;
}

/* Data */
.view-details ul li div span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 15px;
	line-height: 40px;
	font-weight: 300;
	color: #5a6169;
	padding-left: 25px;
}

/* Form */

/* Heading */
.view-update-form h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 500;
	color: #868e96;
	margin-bottom: -35px;
}

/* Inputs */
.view-update-form input {
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
}

.view-update-form select:not(.form-options select) {
	border: none;
	outline: none;
	height: 35px;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 0 10px;
	transition: border-color 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
}

/* Placeholder */
.view-update-form input::placeholder {
	font-size: 12px;
	letter-spacing: 1px;
}

/* Textaera */
.view-update-form textarea {
	border: none;
	outline: none;
	border: 1px solid #e1e5eb;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.3s linear;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #495057;
	resize: vertical;
}

.view-update-form input:hover,
.view-update-form textarea:hover {
	border-color: #b3bdcc;
}

.view-update-form input:focus,
.view-update-form textarea:focus,
.view-update-form select:focus {
	border-color: var(--primary);
	box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
		0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	/* Body */
	.view-body {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 2%;
	}
	/* View details sections */
	.view-details {
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 775px) {
	/* Main Heading */
	.view-heading {
		text-align: center;
	}

	/* Body */
	.view-body {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 2%;
	}

	/* View details sections */
	.view-details {
		margin-bottom: 40px;
	}

	/* Details */
	.view-details ul {
		display: grid;
		grid-template-rows: auto;
		grid-row-gap: 20px;
		padding: 20px;
	}

	/* List items in details section */
	.view-details ul li {
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
	}
}
