/* Pagination */
.pagination {
	margin: 15px auto;
	display: flex;
	justify-content: center;
	list-style: none;
	outline: none;
}
.pagination > .active > a {
	background-color: var(--primary);
	border-color: var(--primary);
	color: #fff;
}
.pagination > li > a {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	border: 1px solid var(--primary);
	padding: 5px 10px;
	outline: none;
	cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: var(--primary);
	border-color: var(--primary);
	outline: none;
}
.pagination > li > a,
.pagination > li > span {
	color: var(--primary);
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 300;
	transition: all 0.2s linear;
}
.pagination > li > a:hover,
.pagination > li > span:hover {
	background-color: rgb(236, 236, 236);
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
}
