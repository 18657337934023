/* Main */
.dashboard {
	background-color: #f2f3f5;
	min-height: 100vh;
	padding: 85px 25px;
}

/* Dashboard Head */
.dashboard-heading span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #818ea3;
	letter-spacing: 2px;
}

.dashboard-heading h3 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 26px;
	color: #3d5170;
}

/* Dashboard Body */
.dashboard-body {
	margin-top: 25px;
	display: grid;
	grid-row-gap: 30px;
}

/* rows */
.dashboard-first-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 2%;
}

.dashboard-second-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 2%;
}

.dashboard-third-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 2%;
}

.dashboard-fourth-row {
	display: grid;
	grid-template-columns: 66% 32%;
	grid-column-gap: 2%;
}

/* Dashboard Item */
.dashboard-item {
	padding: 25px;
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* Chart */
.chart {
	height: 440px;
	padding: 0;
}

/* Chart Heading */
.dashboard-item h2 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #3d5170;
	border-bottom: 1px solid #e7e7e7;
	width: 100%;
	padding: 15px;
}

.dashboard-item canvas {
	padding: 10px;
}

/* Item title */
.dashboard-item span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #818ea3;
	letter-spacing: 1px;
}

/* Item State */
.dashboard-item h6 {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 33px;
	font-weight: 500;
	line-height: 50px;
	color: #3d5170;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1000px) {
	/* Chart */
	.chart {
		height: 300px;
	}
}

@media only screen and (max-width: 775px) {
	/* Main */
	.dashboard {
		text-align: center;
	}

	/* Dashboard Body */
	.dashboard-body {
		margin-top: 25px;
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	/* Rows */
	.dashboard-first-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	.dashboard-second-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	.dashboard-third-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	.dashboard-fourth-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
	}

	/* Chart */
	.chart {
		height: 250px;
	}
}
